/**
 * 整个项目api的管理
 *
 */
import request from "./request"

export default {
    getBusinessType(params) {
        return request({
            url: '/common/getEnumList',
            method: 'get',
            data: params,
            mock: true
        })
    },

    getAllEnumList() {
        return request({
            url: '/common/getAllEnumList',
            method: 'get',
            mock: true
        })
    },

    addKnowledgeItem(params) {
        return request({
            url: '/knowledgeBase/addKnowledge',
            method: 'post',
            data: params,
            mock: true
        })
    },

    editKnowledgeItem(params) {
        return request({
            url: '/knowledgeBase/editKnowledge',
            method: 'post',
            data: params,
            mock: true
        })
    },

    deleteKnowledgeItem(params) {
        return request({
            url: '/knowledgeBase/delKnowledge',
            method:'get',
            data: params,
            mock: true
        })
    },


    getBizList(params) {
        console.log("AGF", params)
        return request({
            url: '/knowledgeBase/getKnowledgePage',
            method: 'post',
            data: params,
            mock: true
        })
    },

    getAllScene(params) {
        return request({
            url: "/knowledgeBase/getAllScene",
            method: 'get',
            data: params,
            mock: true
        })
    },


    addSceneItem (params) {
        return request(({
            url: '/knowledgeBase/addScene',
            method: 'post',
            data: params,
            mock: true
        }))
    },

    deleteSceneItem (params) {
        console.log("deleteSceneItem", params)
        return request(({
            url: '/knowledgeBase/delScene',
            method: 'get',
            data: params,
            mock: true
        }))
    },

    getAllSceneByPage (params) {
        return request(({
            url: '/knowledgeBase/getSceneByPage',
            method: 'post',
            data: params,
            mock: true
        }))
    },

    downImportFile(params) {
      return request(({
          url: '/knowledgeBase/downImportFile',
          method: 'get',
          data: params,
          responseType: 'blob'
      }))
    },

    // home组件 左侧表格数据获取
    getTableData(params) {
        return request({
            url: '/home/getTableData',
            method: 'get',
            data: params,
            mock: true,

        })
    },

}
