import axios from 'axios'
import config from '../config'
import { ElMessage } from 'element-plus'
import {session} from "@/utils/common";

const NETWORK_ERROR = '网络请求异常,请稍后重试.....'
// 创建一个axios实例对象
const service = axios.create({
  baseURL: config.baseApi,
  headers:{
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'accept': 'application/json'
  }
})

// 在请求之前做一些事情
service.interceptors.request.use((config) => {
  // 可以自定义header
  console.log("--------interceptors------");
  const localUser = session('accessToken', undefined)
  console.log("interceptors",localUser)
  if (localUser) {
    config.headers.Authorization = localUser.accessToken
  }
  const localid = session('id', undefined)
  console.log("interceptors",localid)
  if (localid) {
    config.headers.id = localid.id
  }
  // jwt-token认证的时候
  return config
})

// 在请求之后做一些事情
service.interceptors.response.use((res) => {
  console.log(res);
  if (res.config.responseType === "blob") {
    let blob = new Blob([res.data],{type: res.headers['content-type']});
    // 创建新的URL并指向File对象或者Blob对象的地址
    const blobURL = window.URL.createObjectURL(blob)
    // 创建a标签，用于跳转至下载链接
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    const contentDisposition = res.headers['content-disposition'] || 'attachment;filename=Download';
    tempLink.setAttribute('download', decodeURI(contentDisposition.split(';')[1].split('=')[1]))
    // 兼容：某些浏览器不支持HTML5的download属性
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }
    // 挂载a标签
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    // 释放blob URL地址
    window.URL.revokeObjectURL(blobURL)
  } else {
    const { status, success ,data, message } = res.data
    // 根据后端 协商  视情况而定
    if (success && status === '200') {
      return data
    } else {
      // 网络请求错误027004007
      if (['4001'].includes(status)) {
        ElMessage.error('登录失效');

        // router.push({ path: "/login" });
        return Promise.reject({status , message})
      } else {
        ElMessage.error(message || NETWORK_ERROR)
        return Promise.reject({status , message})
      }
    }
  }
})

// 封装的核心函数
function request(options) {
  options.method = options.method || 'get'
  if (options.method.toLowerCase() == 'get') {
    options.params = options.data
  }
  // 对mock的处理
  let isMock = config.mock
  // if (typeof options.mock !== 'undefined') {
  //   isMock = options.mock
  // }
  // 对线上环境做处理
  if (config.env == 'production') {
    // 不给你用到mock的机会
    service.defaults.baseURL = config.baseApi
  } else {
    service.defaults.baseURL = isMock ? config.mockApi : config.baseApi
  }
  console.log(service.defaults.baseURL)
  return service(options)
}
export default request
