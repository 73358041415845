import {createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import {session} from "@/utils/common";

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path:'/',
            component: ()=> import('../views/Main.vue'),
            redirect:'/home',
            meta: {
                isRequired: true
            }
        },
        {
            path:'/login',
            name:'login',
            component:() => import('../views/common/login.vue')
        },
        {
            path:'/wxlogin',
            name:'wxlogin',
            component:() => import('../views/common/wxlogin.vue')
        },
        {
            path: '/main',
            component: ()=> import('../views/Main.vue'),
            redirect:'/home',
            meta: {
                isRequired: true
            },
            children: [
                {
                    path:'/home',
                    name:'home',
                    component:() => import('../views/HomeView.vue')
                },
                {
                    path:'/case',
                    name:'case',
                    component:() => import('../views/CaseNewView.vue')
                },
                {
                    path:'/mycaselist',
                    name:'mycaselist',
                    component:() => import('../views/MyCaseList.vue')
                },
                {
                    path:'/usecase',
                    name:'usecase',
                    component:() => import('../views/UseCase.vue')
                },
                {
                    path: '/pointerRecharge',
                    name: 'pointerRecharge',
                    component:() => import('../views/PointerRecharge.vue')
                },
            ]
        }
    ]
})


router.beforeEach((to, from, next) => {
    if (to.meta.isRequired) {   // 如果该路由需要验证
        const localUser = session('accessToken', undefined)
        if (localUser && localUser.accessToken) {  // token存在即进入该路由
            next()
        } else {   // 不存在则跳转至登录页面
            next({
                path: '/login',
            })
        }
    } else {   // 不需要验证直接跳转
        next()
    }
})

export default router
