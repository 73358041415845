
// sessionStorage
export const session = function(key, value) {
  // debugger
  if (value === void 0) {
    const lsVal = sessionStorage.getItem(key)
    return JSON.parse(lsVal)
  }
  return sessionStorage.setItem(key, JSON.stringify(value))
}
