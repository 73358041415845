<template>
    <RouterView />
</template>

<script>

export default {
    name: 'App',
    components: {

    }
}
</script>

<style>
#app {
    height: 100%;
}
</style>
